import { requestMethod } from "../../config";
import config from "../../env.config";
import { fetch } from "../../shared/services/api.service";
import { checkApiSuccess } from "../../utils";

export const updateKanbanView = async (payload) => {
  const requestConfig = {
      method: requestMethod.POST,
      url: `${config.BASE_URL.BASE_API_URL}/task/updateKanbanView`,
      data: payload,
    },
    response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const getKanbanView = async (payload) => {
  const requestConfig = {
      method: requestMethod.GET,
      url: `${config.BASE_URL.BASE_API_URL}/task/getKanbanView`,
      params: payload,
    },
    response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};

export const getTaskWorkflow = async (params, signal) => {
  const requestConfig = {
    method: requestMethod.GET,
    url: `${config.BASE_URL.BASE_API_URL}/task/getTaskWorkflowDetails`,
    params: params,
  };
  const response = await fetch(requestConfig, false, false);
  if (checkApiSuccess(response)) {
    return response.data.data;
  }
};
