import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import DynamicReminderPopup from "../Tasks/Reminders/DynamicReminderPopup";
import { useReminderContext } from "../Tasks/Reminders/ReminderContextProvider";
import ReminderList from "../Tasks/Reminders/ReminderList";
import "../Tasks/Reminders/reminder.scss";
import { useWorkflowContext } from "./WorkflowContextProvider";
import WorkflowReminderEditPopup from "./WorkflowReminderEditPopup";
import { useWorkflowDynamicReminderPopup, useWorkflowReminderList } from "./workflowRemindersHook";

const HandleWorkflowReminder = ({ anchor, show, toggleShowPopup, setShowPopup }) => {
  const [showCustomPopup, setShowCustomPopup] = useState(false);
  const { isAddClicked, isReminderClicked, setIsReminderClicked } = useReminderContext();
  const { workflowReminders, editName, editRem, editNoOfDays } = useWorkflowContext();
  const { popupBodyContent, handleSaveReminder } = useWorkflowDynamicReminderPopup(setShowPopup);
  const { handleClick, delReminder, setDelReminder, handleAddRem } = useWorkflowReminderList(onClick);

  const onClick = () => {
    setShowPopup(false);
  };
  /**
   *  sets the reminderMessage
   *  @author: Sarthak Arora
   */

  const handleCancel = () => {
    setShowPopup(false);
  };

  const closeCustomPopup = () => {
    setShowCustomPopup(false);
  };

  /**
   * renders the reminder details in Reminder List
   * @param {*} reminder
   */
  const reminderDetails = (reminder) => {
    return <div className='small text-secondary'>{`${reminder.NoOfDays} days before due date`}</div>;
  };

  return (
    <div>
      {isAddClicked || workflowReminders.length === 0 ? (
        <DynamicReminderPopup
          popupAnchor={anchor}
          show={show}
          onClick={toggleShowPopup}
          setShowReminder={setShowPopup}
          popupBodyContent={popupBodyContent}
          handleCancel={handleCancel}
          handleSaveReminder={handleSaveReminder}
          showCustomPopup={showCustomPopup}
          closeCustomPopup={closeCustomPopup}
        />
      ) : (
        <ReminderList
          onClick={onClick}
          show={show}
          reminders={workflowReminders}
          anchor={anchor}
          reminderDetails={reminderDetails}
          handleAddRem={handleAddRem}
          setDelReminder={setDelReminder}
          handleClick={handleClick}
          delReminder={delReminder}
        />
      )}
      {isReminderClicked && (
        <WorkflowReminderEditPopup
          onClick={onClick}
          ReminderId={editRem}
          reminderMessage={editName}
          noOfDays={editNoOfDays}
          handleAddRem={handleAddRem}
          popupAnchor={anchor}
          show={show}
          setIsReminderClicked={setIsReminderClicked}
        />
      )}
    </div>
  );
};

export default HandleWorkflowReminder;
